import React, { useState, useEffect, useCallback } from 'react';
import useImage from 'use-image';
import InfoTab from './tabs/InfoTab';
import RestTab from './tabs/RestTab';
import GraphQLTab from './tabs/GraphQLTab';
import SQLTab from './tabs/SQLTab';

// Инструменты
const tools = [
  { name: 'Информация о компоненте', icon: '/icons/info.png', width: 400 },
  { name: 'Выполнить REST запрос', icon: '/icons/rest.png', width: 600 },
  { name: 'Выполнить GraphQL запрос', icon: '/icons/graphql.png', width: 600 },
  { name: 'Выполнить SQL запрос', icon: '/icons/sql.png', width: 500 }
];

const TOOL_PANEL_WIDTH = 48;

// Компонент для панели инструментов
const ToolPanel = ({ activeTool, handleToolChange, icons }) => (
    <div
        className="flex flex-col bg-transparent overflow-y-auto justify-start"
        style={{width: TOOL_PANEL_WIDTH}}
    >
      {tools.map((tool) => (
          <div className={'mt-6 mr-4'}>
          <button
              key={tool.name}
              onClick={() => handleToolChange(tool)}
              className={`
          flex items-center justify-center w-full h-12 rounded
          ${activeTool === tool.name ? 'bg-gray-100' : 'hover:bg-gray-50'}
          transition-colors duration-200 ease-in-out
        `}
              title={tool.name}
          >
            {icons[tool.name] ? (
                <img
                    src={icons[tool.name].src}
                    alt={tool.name}
                    className="w-6 h-6"
                />
            ) : (
                '...'
            )}
          </button>
          </div>
      ))}
    </div>
);

// Компонент для панели с вкладками
const TabPanel = ({activeTool, panelWidth, isCollapsed, activeComponent}) => {
  const renderContent = () => {
    switch (activeTool) {
      case 'Информация о компоненте':
        return <InfoTab activeComponent={activeComponent}/>;
      case 'Выполнить REST запрос':
        return <RestTab activeComponent={activeComponent}/>;
      case 'Выполнить GraphQL запрос':
        return <GraphQLTab/>;
      case 'Выполнить SQL запрос':
        return <SQLTab/>;
      default:
        return null;
    }
  };

  return (
      <div
          className={`bg-transparent absolute top-0 h-full overflow-hidden ${
              isCollapsed ? '' : ''
          }`}
          style={{
            right: `${TOOL_PANEL_WIDTH}px`,
            width: isCollapsed ? '0' : `${panelWidth - TOOL_PANEL_WIDTH}px`,
            transition: 'width 0.3s ease',
          }}
      >
        <div
            className={`h-full overflow-auto ${isCollapsed ? '' : 'p-4'}`}
            style={{ display: isCollapsed ? 'none' : 'block' }}
        >
          {renderContent()}
        </div>
      </div>
  );
};

// Основной компонент панели с инструментами и вкладками
const InfoPanel = ({activeComponent}) => {
  const [activeTool, setActiveTool] = useState(null);
  const [panelWidth, setPanelWidth] = useState(TOOL_PANEL_WIDTH);
  const [isCollapsed, setIsCollapsed] = useState(true);

  // Загружаем иконки
  const [infoIcon] = useImage('/icons/info.png');
  const [restIcon] = useImage('/icons/rest.png');
  const [graphqlIcon] = useImage('/icons/graphql.png');
  const [sqlIcon] = useImage('/icons/sql.png');

  // Массив с загруженными иконками
  const icons = {
    'Информация о компоненте': infoIcon,
    'Выполнить REST запрос': restIcon,
    'Выполнить GraphQL запрос': graphqlIcon,
    'Выполнить SQL запрос': sqlIcon
  };

  useEffect(() => {
    if (activeComponent) {
      setActiveTool('Информация о компоненте');
      setPanelWidth(TOOL_PANEL_WIDTH + 400);
      setIsCollapsed(false);
    }
  }, [activeComponent]);

  const handleToolChange = useCallback((tool) => {
    if (isCollapsed || tool.name !== activeTool) {
      setActiveTool(tool.name);
      setPanelWidth(TOOL_PANEL_WIDTH + tool.width);
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
      setPanelWidth(TOOL_PANEL_WIDTH);
    }
  }, [isCollapsed, activeTool]);

  return (
      <div
          className="relative h-full overflow-visible"
          style={{ width: `${TOOL_PANEL_WIDTH}px` }}
      >
        <ToolPanel
            activeTool={activeTool}
            handleToolChange={handleToolChange}
            icons={icons}
        />
        <TabPanel
            activeTool={activeTool}
            panelWidth={panelWidth}
            isCollapsed={isCollapsed}
            activeComponent={activeComponent}
        />
      </div>
  );
};

export default InfoPanel;