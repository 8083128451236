import React, { useState, useEffect } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/worker-json';
import 'ace-builds/src-noconflict/ext-language_tools';
import { Button, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Устанавливаем путь для воркеров через CDN
const ace = require('ace-builds/src-noconflict/ace');
ace.config.set('basePath', 'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.12/');
ace.config.set('modePath', 'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.12/');
ace.config.set('workerPath', 'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.12/');
ace.config.set('themePath', 'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.12/');

const RestTab = ({ activeComponent }) => {
    const [method, setMethod] = useState('GET');
    const [url, setUrl] = useState('');
    const [requestBody, setRequestBody] = useState('{}');
    const [response, setResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        if (activeComponent && activeComponent.host) {
            setUrl(`http://${activeComponent.host}:${activeComponent.port}`);
        }
    }, [activeComponent]);

    const sendRequest = async () => {
        setIsLoading(true);
        setResponse(null);
        try {
            const options = {
                method,
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            if (method !== 'GET' && method !== 'DELETE') {
                options.body = requestBody;
            }

            const res = await fetch(url, options);
            const data = await res.json();
            setResponse(data);
        } catch (error) {
            console.error('Error during fetch:', error);
            setResponse({ error: 'Произошла ошибка при выполнении запроса: ' + error.message });
        } finally {
            setIsLoading(false);
        }
    };

    const openDetails = (item) => {
        setSelectedItem(item);
    };

    const closeDetails = () => {
        setSelectedItem(null);
    };

    const generateEntityJsonExample = (entity) => {
        const example = {};
        entity.fields.forEach(field => {
            example[field.name] = field.type === 'integer' ? 123 : field.type === 'string' ? 'example' : null;
        });
        return example;
    };

    return (
        <div className="relative mx-auto" style={{ maxWidth: '90%' }}>
            <div className="grid grid-cols-3 gap-y-4 gap-x-6 text-sm font-mono text-gray-700 bg-gray-100 p-4 rounded-lg shadow-md">
                <FormControl fullWidth variant="outlined" className="col-span-1">
                    <InputLabel>Метод</InputLabel>
                    <Select
                        value={method}
                        onChange={(e) => setMethod(e.target.value)}
                        label="Метод"
                    >
                        <MenuItem value="GET">GET</MenuItem>
                        <MenuItem value="POST">POST</MenuItem>
                        <MenuItem value="PUT">PUT</MenuItem>
                        <MenuItem value="DELETE">DELETE</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    fullWidth
                    variant="outlined"
                    label="URL"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    placeholder="https://example.com/api"
                    className="col-span-2"
                />

                {method !== 'GET' && method !== 'DELETE' && (
                    <div className="col-span-3">
                        <AceEditor
                            mode="json"
                            theme="github"
                            value={requestBody}
                            onChange={(value) => setRequestBody(value)}
                            name="request_body_editor"
                            editorProps={{ $blockScrolling: true }}
                            width="100%"
                            height="200px"
                        />
                    </div>
                )}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={sendRequest}
                    disabled={isLoading || !url}
                    className="col-span-3"
                >
                    {isLoading ? 'Отправка...' : 'Отправить запрос'}
                </Button>
            </div>

            {response && (
                <div className="mt-6 p-4 bg-gray-100 rounded-lg shadow-md">
                    <h4 className="text-lg font-bold">Ответ:</h4>
                    <pre className="bg-gray-900 text-gray-100 p-4 rounded">
                        {JSON.stringify(response, null, 2)}
                    </pre>
                </div>
            )}

            {activeComponent && activeComponent.type_name === 'Microservice' && (
                <div className="mt-6 p-4 bg-gray-100 rounded-lg shadow-md">
                    <h4 className="text-lg font-bold mb-4">Информация о компоненте:</h4>

                    {activeComponent.configuration?.endpoints && (
                        <div className="mb-4">
                            <h5 className="text-md font-semibold mb-2">Эндпоинты:</h5>
                            <div className="space-y-2">
                                {activeComponent.configuration.endpoints.map((endpoint, index) => (
                                    <div
                                        key={index}
                                        className="text-blue-600 cursor-pointer hover:underline"
                                        onClick={() => openDetails(endpoint)}
                                    >
                                        {endpoint.methods.join(', ')} {endpoint.route}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {activeComponent.configuration?.entities && (
                        <div>
                            <h5 className="text-md font-semibold mb-2">Сущности:</h5>
                            <div className="space-y-2">
                                {activeComponent.configuration.entities.map((entity, index) => (
                                    <div
                                        key={index}
                                        className="text-blue-600 cursor-pointer hover:underline"
                                        onClick={() => openDetails(entity)}
                                    >
                                        {entity.name}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}

            {selectedItem && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={closeDetails}>
                    <div className="bg-gray-100 p-6 rounded-lg shadow-lg w-3/4 max-w-lg relative text-sm font-mono text-gray-700" onClick={(e) => e.stopPropagation()}>
                        <button className="absolute top-2 right-2 text-gray-400 hover:text-gray-600" onClick={closeDetails}>
                            <CloseIcon />
                        </button>

                        {selectedItem.route ? (
                            <>
                                <h3 className="text-xl font-bold mb-4">Эндпоинт: {selectedItem.route}</h3>
                                <p className="text-gray-700 mb-2"><strong>Методы:</strong> {selectedItem.methods.join(', ')}</p>
                                <p className="text-gray-700 mb-2"><strong>Описание:</strong> {selectedItem.description}</p>

                                <div className="mb-4">
                                    <strong>Входные параметры:</strong>
                                    <ul className="list-disc list-inside">
                                        {selectedItem.inputs.map((input, index) => (
                                            <li key={index} className="text-gray-700">
                                                {input.name} ({input.type}) {input.required ? '(Обязательный)' : '(Необязательный)'}
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                <div className="mb-4">
                                    <strong>Выходные параметры:</strong>
                                    <ul className="list-disc list-inside">
                                        {selectedItem.outputs.map((output, index) => (
                                            <li key={index} className="text-gray-700">
                                                {output.name} ({output.type})
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        ) : (
                            <>
                                <h3 className="text-xl font-bold mb-4">Сущность: {selectedItem.name}</h3>
                                <p className="text-gray-700 mb-2"><strong>Описание:</strong> {selectedItem.description}</p>

                                <div className="mb-4">
                                    <strong>Поля сущности:</strong>
                                    <ul className="list-disc list-inside">
                                        {selectedItem.fields.map((field, index) => (
                                            <li key={index} className="text-gray-700">
                                                <span className="font-bold text-gray-600 font-mono">{field.name}</span>
                                                {field.nullable ? (
                                                    <span className="text-gray-600"></span>
                                                ) : (
                                                    <span className="text-red-600">*</span>
                                                )}
                                                <span className="text-gray-600"> ({field.type})</span>
                                                <span className="text-gray-500"> - {field.description}</span>
                                            </li>
                                        ))}
                                    </ul>
                                    <span className="text-gray-500 p-1"> * - обязательное поле</span>
                                </div>

                                <div className="mb-4">
                                    <strong>Пример JSON:</strong>
                                    <pre className="bg-gray-900 text-gray-100 p-2 rounded">
                                        {JSON.stringify(generateEntityJsonExample(selectedItem), null, 2)}
                                    </pre>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default RestTab;