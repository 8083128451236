import React, { useRef, useEffect } from 'react';

const ContextMenu = ({ position, onAction }) => {
    const contextMenuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
                onAction(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onAction]);

    return (
        <div
            ref={contextMenuRef}
            className="absolute bg-white border border-gray-200 shadow-lg rounded-md z-50 min-w-[150px] text-sm"
            style={{ top: position.y, left: position.x }}
        >
            <div
                className="p-2 cursor-pointer hover:bg-blue-500 hover:text-white"
                onClick={() => onAction('information')}
            >
                Что это?
            </div>
            <div
                className="p-2 cursor-pointer hover:bg-blue-500 hover:text-white"
                onClick={() => onAction('recreate')}
            >
                Объясни
            </div>
            <div
                className="p-2 cursor-pointer hover:bg-blue-500 hover:text-white"
                onClick={() => onAction('delete')}
            >
                Удалить
            </div>
        </div>
    );
};

export default ContextMenu;
