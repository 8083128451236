import React from 'react';

const SQLTab = ({ componentInfo, activeComponent }) => (
    <div className="p-4">
        {activeComponent?.name && (
            <h3 className="text-lg mb-2">{activeComponent.name}</h3>
        )}
        {/* Заглушка для инструмента */}
        <div className="bg-gray-100 p-4 rounded">
            <p className="text-center text-gray-600 font-semibold">Инструмент в разработке</p>
        </div>
    </div>
);

export default SQLTab;