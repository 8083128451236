import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { Stage, Layer, Line, Shape } from 'react-konva';
import ComponentRenderer from './canvas/CanvasComponents';
import ContextMenu from './canvas/ContextMenu';
import useCanvasHandlers from './canvas/useCanvasHandlers';
import Cookies from 'js-cookie';
import debounce from 'lodash.debounce';

// Размеры иконок
const iconWidth = 50;
const iconHeight = 50;

const SystemComponent = ({ name, x, y, width, height, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
      <Shape
          x={x}
          y={y}
          sceneFunc={(context, shape) => {
            context.beginPath();
            const gradient = context.createLinearGradient(0, 0, width, height);
            gradient.addColorStop(0, '#FF6F00');
            gradient.addColorStop(1, '#FF8F00');
            context.fillStyle = gradient;

            // Рисуем прямоугольник с закругленными углами
            const radius = 10;
            context.moveTo(radius, 0);
            context.lineTo(width - radius, 0);
            context.quadraticCurveTo(width, 0, width, radius);
            context.lineTo(width, height - radius);
            context.quadraticCurveTo(width, height, width - radius, height);
            context.lineTo(radius, height);
            context.quadraticCurveTo(0, height, 0, height - radius);
            context.lineTo(0, radius);
            context.quadraticCurveTo(0, 0, radius, 0);
            context.closePath();

            context.fillStrokeShape(shape);

            // Добавляем тень
            context.shadowColor = 'rgba(0,0,0,0.3)';
            context.shadowBlur = 5;
            context.shadowOffsetX = 2;
            context.shadowOffsetY = 2;

            // Текст
            context.fillStyle = 'white';
            context.font = '14px Arial';
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            context.fillText(name, width / 2, height / 2);
          }}
          fill={isHovered ? '#FF8F00' : '#FF6F00'}
          stroke="#E65100"
          strokeWidth={2}
          onMouseEnter={(e) => {
            const stage = e.target.getStage();
            stage.container().style.cursor = 'pointer';
            setIsHovered(true);
          }}
          onMouseLeave={(e) => {
            const stage = e.target.getStage();
            stage.container().style.cursor = 'default';
            setIsHovered(false);
          }}
          onClick={onClick}
          onTap={onClick}
      />
  );
};


// Распределение компонентов по уровням
const distributeComponentsByLevels = (components, canvasWidth) => {
  const levels = {};

  // Группируем компоненты по уровням
  components.forEach((comp) => {
    if (!levels[comp.type_level]) {
      levels[comp.type_level] = [];
    }
    levels[comp.type_level].push(comp);
  });

  const placedComponents = [];
  const levelOffset = 150;
  const componentSpacing = 120;

  // Перебираем уровни
  Object.keys(levels).forEach((level, levelIndex) => {
    const levelComponents = levels[level];

    // Рассчитываем ширину, которую занимают компоненты на уровне
    const totalLevelWidth = levelComponents.length * componentSpacing; // Добавляем отступ между компонентами

    // Рассчитываем смещение для центрирования уровня на основе его ширины
    const startX = Math.max(0, (canvasWidth - totalLevelWidth) / 2);

    // Расставляем компоненты
    levelComponents.forEach((comp, index) => {
      placedComponents.push({
        ...comp,
        x: startX + index * (iconWidth + 60), // Добавляем отступ между компонентами
        y: levelIndex * levelOffset + 50, // Добавляем отступ сверху
        width: iconWidth,
        height: iconHeight,
      });
    });
  });

  return placedComponents;
};

const SandboxCanvas = ({
                         components,
                         setComponents,
                         setActiveComponent,
                         relations,
                         containerRef,
                         processStage,
                         currentLayer,
                         sandboxName,
                         onLayerChange,
                       }) => {
  const stageRef = useRef(null);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [contextMenu, setContextMenu] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [lastPointerPosition, setLastPointerPosition] = useState(null);

  // Состояние для всплывающего сообщения
  const [tooltip, setTooltip] = useState({
    visible: false,
    x: 0,
    y: 0,
    description: '',
  });

  useCanvasHandlers({
    containerRef,
    stageRef,
    dimensions,
    setDimensions,
    setScale,
    setPosition,
    setContextMenu,
    components,
    setComponents,
    currentLayer,
    sandboxName,
    onLayerChange,
  });

  // Функция для сохранения позиций компонентов в куки (с дебаунсом)
  const saveComponentsToCookies = useMemo(() => {
    return debounce((componentsToSave) => {
      const componentsData = componentsToSave.map((comp) => ({
        id: comp.id,
        x: comp.x,
        y: comp.y,
      }));

      const isDataValid = componentsData.every(
          (comp) => typeof comp.x === 'number' && typeof comp.y === 'number'
      );
      if (!isDataValid) {
        return;
      }

      Cookies.set('sandboxComponents', JSON.stringify(componentsData));
    }, 500);
  }, []);

  // Функция для загрузки позиций компонентов из куки
  const loadComponentsFromCookies = () => {
    try {
      const savedComponents = Cookies.get('sandboxComponents');
      return savedComponents ? JSON.parse(savedComponents) : null;
    } catch (error) {
      console.error('Ошибка при загрузке компонентов из cookies:', error);
      return null;
    }
  };

  // Используем ResizeObserver для обновления размеров контейнера
    useEffect(() => {
        if (!containerRef.current) return;

        const resizeObserver = new ResizeObserver((entries) => {
            if (entries && entries.length > 0) {
                const { width, height } = entries[0].contentRect;
                console.log('Container dimensions:', width, height);
                if (width > 0 && height > 0) {
                    setDimensions({ width, height });
                }
            }
        });

        resizeObserver.observe(containerRef.current);

        // Очистка при размонтировании
        return () => {
            resizeObserver.disconnect();
        };
    }, [containerRef]);

    useEffect(() => {
        if (containerRef.current) {
            const { clientHeight, clientWidth } = containerRef.current;
            // Здесь можно обновить состояние в зависимости от размеров контейнера
            console.log('Размеры контейнера:', clientHeight, clientWidth);
        }
    }, [containerRef]);

  // Первичная загрузка компонентов и их распределение по сетке или из куки
  useEffect(() => {
    if (!isInitialized && dimensions.width > 0 && dimensions.height > 0 && components.length > 0) {
      const savedComponents = loadComponentsFromCookies();

      setComponents((prevComponents) => {
        let updatedComponents;

        if (savedComponents && savedComponents.length === prevComponents.length) {
          // Обновляем позиции компонентов из сохраненных данных
          updatedComponents = prevComponents.map((comp) => {
            const savedComp = savedComponents.find((sc) => sc.id === comp.id);
            return savedComp
                ? { ...comp, x: savedComp.x, y: savedComp.y }
                : comp;
          });
        } else {
          // Распределяем компоненты по уровням, если нет сохраненных данных или их количество не совпадает
          updatedComponents = distributeComponentsByLevels(prevComponents, dimensions.width);
        }

        return updatedComponents;
      });

      setIsInitialized(true);
    }
  }, [dimensions.width, dimensions.height, setComponents, components, isInitialized]);

  // Сохраняем компоненты в cookies при их изменении
  useEffect(() => {
    if (isInitialized && components.length > 0) {
      saveComponentsToCookies(components);
    }
  }, [components, saveComponentsToCookies, isInitialized]);

  const handleContextMenu = useCallback((e, comp) => {
    e.evt.preventDefault();
    if (!stageRef.current) return;
    const pointer = stageRef.current.getPointerPosition();
    if (!pointer) return;
    setContextMenu({
      x: pointer.x,
      y: pointer.y,
      compId: comp.id,
    });
  }, []);

  const handleStageMouseDown = useCallback((e) => {
    if (e.target === e.target.getStage()) {
      setIsDragging(true);
      setLastPointerPosition(e.target.getStage().getPointerPosition());
    }
  }, []);

  const handleStageMouseMove = useCallback((e) => {
    if (!isDragging) return;

    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();
    const dragDiffX = pointerPosition.x - lastPointerPosition.x;
    const dragDiffY = pointerPosition.y - lastPointerPosition.y;

    setPosition(prevPosition => ({
      x: prevPosition.x + dragDiffX,
      y: prevPosition.y + dragDiffY,
    }));

    setLastPointerPosition(pointerPosition);
  }, [isDragging, lastPointerPosition]);

  const handleStageMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleComponentClick = useCallback(
      (comp) => {
        setActiveComponent((prevActiveComponent) => {
          if (prevActiveComponent && prevActiveComponent.id === comp.id) {
            // Если кликнули на уже активный компонент, закрываем панель
            return null;
          }
          // Иначе устанавливаем новый активный компонент
          return comp;
        });
      },
      [setActiveComponent]
  );

  const [dashOffset, setDashOffset] = useState(0);
  useEffect(() => {
    const anim = setInterval(() => {
      setDashOffset((prevOffset) => (prevOffset + 1) % 10);
    }, 100);
    return () => clearInterval(anim);
  }, []);

  // Функции для работы с всплывающим сообщением
  const handleMouseEnter = useCallback((e, relation) => {
    if (!stageRef.current) return;
    const pointerPosition = stageRef.current.getPointerPosition();
    if (!pointerPosition) return;

    setTooltip({
      visible: true,
      x: pointerPosition.x + 10,
      y: pointerPosition.y + 10,
      description: relation.description || 'Нет описания',
    });
  }, []);

  const handleMouseLeave = useCallback(() => {
    setTooltip({ visible: false, x: 0, y: 0, description: '' });
  }, []);

  const handleSystemClick = useCallback(() => {
    onLayerChange('Подробная архитектура');
  }, [onLayerChange]);

  const handleDragMove = useCallback((e, id) => {
    const { x, y } = e.target.position();
    const gridSize = 20; // Уменьшаем размер сетки для более плавного перемещения
    const padding = 4;

    let newX = Math.round(x / gridSize) * gridSize + padding;
    let newY = Math.round(y / gridSize) * gridSize + padding;

    newX = Math.max(0, Math.min(newX, dimensions.width - iconWidth));
    newY = Math.max(0, Math.min(newY, dimensions.height - iconHeight));

    setComponents((prevComponents) =>
        prevComponents.map((comp) =>
            comp.id === id ? { ...comp, x: newX, y: newY } : comp
        )
    );
  }, [dimensions.width, dimensions.height, setComponents]);

  const MemoizedComponentRenderer = React.memo(ComponentRenderer);

  const memoizedComponents = useMemo(() => {
    if (currentLayer === 'Верхнеуровневая архитектура') {
      const environmentComponents = components.filter(comp => comp.category_name === 'environment');

      // Вычисляем размеры и позицию для системного компонента
      const systemWidth = Math.min(200, dimensions.width * 0.2);
      const systemHeight = Math.min(100, dimensions.height * 0.1);
      const systemX = (dimensions.width - systemWidth) / 2;
      const systemY = (dimensions.height - systemHeight) / 2;

      const systemComponent = {
        id: 'system',
        name: sandboxName,
        category_name: 'system',
        type_name: 'System',
        x: systemX,
        y: systemY,
        width: systemWidth,
        height: systemHeight,
        description: 'Сгенерированная система. Кликните для просмотра подробной архитектуры'
      };

      // Распределяем компоненты окружения вокруг системного компонента
      const angle = (2 * Math.PI) / environmentComponents.length;
      const radius = Math.min(dimensions.width, dimensions.height) * 0.3;

      environmentComponents.forEach((comp, index) => {
        comp.x = systemX + systemWidth / 2 + radius * Math.cos(angle * index) - iconWidth / 2;
        comp.y = systemY + systemHeight / 2 + radius * Math.sin(angle * index) - iconHeight / 2;
      });

      return [
        ...environmentComponents.map(comp => (
            <MemoizedComponentRenderer
                key={comp.id}
                comp={comp}
                onContextMenu={handleContextMenu}
                onClick={handleComponentClick}
                onDragMove={handleDragMove}
            />
        )),
        <SystemComponent
            key="system"
            {...systemComponent}
            onClick={handleSystemClick}
        />,
      ];
    } else {
      return components.map((comp) => (
          <MemoizedComponentRenderer
              key={comp.id}
              comp={comp}
              onContextMenu={handleContextMenu}
              onClick={handleComponentClick}
              onDragMove={handleDragMove}
          />
      ));
    }
  }, [components, currentLayer, sandboxName, dimensions, handleContextMenu, handleComponentClick, handleDragMove, handleSystemClick]);

  const memoizedComponentPositions = useMemo(() => {
    return components.reduce((acc, comp) => {
      if (typeof comp.x === 'number' && typeof comp.y === 'number') {
        acc[comp.id] = {
          x: comp.x,
          y: comp.y,
          width: iconWidth,
          height: iconHeight,
        };
      }
      return acc;
    }, {});
  }, [components]);

  // Функция для получения позиции компонента по id и расчета его центра
  const getComponentPosition = useCallback(
      (id) => {
        const component = components.find((comp) => comp.id === id);
        return component || memoizedComponentPositions[id] || null;
      },
      [components, memoizedComponentPositions]
  );

  const memoizedRelations = useMemo(() => {
    if (currentLayer === 'Верхнеуровневая архитектура') {
      // В верхнеуровневой архитектуре связываем все компоненты с центральной фигурой
      const centerX = dimensions.width / 2;
      const centerY = dimensions.height / 2;

      return components
          .filter(comp => comp.category_name === 'environment')
          .map((comp) => {
            const compPos = getComponentPosition(comp.id);
            if (!compPos) return null;

            const fromXCenter = compPos.x + (compPos.width || iconWidth) / 2;
            const fromYCenter = compPos.y + (compPos.height || iconHeight) / 2;

            return (
                <React.Fragment key={comp.id}>
                  <Line
                      points={[fromXCenter, fromYCenter, centerX, centerY]}
                      stroke="transparent"
                      strokeWidth={6}
                      onMouseEnter={(e) => handleMouseEnter(e, { description: `Связь с ${comp.name}` })}
                      onMouseLeave={handleMouseLeave}
                  />
                  <Line
                      points={[fromXCenter, fromYCenter, centerX, centerY]}
                      stroke="gray"
                      strokeWidth={1}
                      dash={[5, 5]}
                      dashOffset={dashOffset}
                  />
                </React.Fragment>
            );
          })
          .filter(Boolean);
    } else {
      // Для подробной архитектуры оставляем прежнюю логику
      return relations
          .map((relation) => {
            const fromPos = getComponentPosition(relation.from_component);
            const toPos = getComponentPosition(relation.to_component);

            if (!fromPos || !toPos) return null;

            const fromXCenter = fromPos.x + (fromPos.width || iconWidth) / 2;
            const fromYCenter = fromPos.y + (fromPos.height || iconHeight) / 2;
            const toXCenter = toPos.x + (toPos.width || iconWidth) / 2;
            const toYCenter = toPos.y + (toPos.height || iconHeight) / 2;

            return (
                <React.Fragment key={relation.id}>
                  <Line
                      points={[fromXCenter, fromYCenter, toXCenter, toYCenter]}
                      stroke="transparent"
                      strokeWidth={6}
                      onMouseEnter={(e) => handleMouseEnter(e, relation)}
                      onMouseLeave={handleMouseLeave}
                  />
                  <Line
                      points={[fromXCenter, fromYCenter, toXCenter, toYCenter]}
                      stroke="gray"
                      strokeWidth={1}
                      dash={[5, 5]}
                      dashOffset={dashOffset}
                  />
                </React.Fragment>
            );
          })
          .filter(Boolean);
    }
  }, [currentLayer, components, relations, dimensions, dashOffset, handleMouseEnter, handleMouseLeave, getComponentPosition]);


  // Проверка размеров после вызова хуков
  if (dimensions.width === 0 || dimensions.height === 0) {
    return null;
  }

  return (
      <div
          onContextMenu={(e) => e.preventDefault()}
      >
        <Stage
            width={dimensions.width}
            height={dimensions.height}
            scaleX={scale}
            scaleY={scale}
            x={position.x}
            y={position.y}
            ref={stageRef}
            onMouseDown={handleStageMouseDown}
            onMouseMove={handleStageMouseMove}
            onMouseUp={handleStageMouseUp}
            onMouseLeave={handleStageMouseUp}
        >
          <Layer>{memoizedRelations}</Layer>
          <Layer>{memoizedComponents}</Layer>
        </Stage>

        {/* Всплывающее сообщение */}
        {tooltip.visible && (
            <div
                style={{
                  position: 'absolute',
                  top: tooltip.y,
                  left: tooltip.x,
                  background: 'rgba(211, 211, 211, 0.5)',
                  padding: '5px',
                  borderRadius: '4px',
                  pointerEvents: 'none',
                  fontSize: '12px',
                  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
                }}
            >
              {tooltip.description}
            </div>
        )}

        {contextMenu && (
            <ContextMenu
                position={contextMenu}
                onAction={(action) => {
                  setContextMenu(null);
                }}
            />
        )}
      </div>
  );
};

export default SandboxCanvas;