import React, { useContext } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Sandbox from './pages/Sandbox';
import Dashboard from './components/Dashboard';
import MainContent from './components/MainContent';
import Courses from './pages/Courses';
import Demo from './pages/Demo';
import About from './pages/About';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';
import { AuthContext } from './App';

const AppRoutes = ({
                       handleOpenLogin,
                       handleLoginSuccess,
                       handleLogout,
                       loginOpen,
                       handleCloseLogin,
                       registerOpen,
                       handleCloseRegister,
                       handleRegisterSuccess,
                   }) => {
    const navigate = useNavigate();
    const { isAuthenticated, userInfo, token, isLoading } = useContext(AuthContext);

    const onLoginSuccess = async (userToken) => {
        await handleLoginSuccess(userToken);
        navigate('/');
    };

    if (isLoading) {
        return <div>Loading...</div>; // или любой другой индикатор загрузки
    }

    return (
        <>
            <div
                style={{paddingTop: '75px', height: 'calc(100vh - 90px)'}}
                className="flex w-full"
            >
                <Routes>
                    <Route
                        path="/"
                        element={isAuthenticated ? <Dashboard userInfo={userInfo} token={token}/> : <MainContent/>}
                    />
                    <Route path="/courses" element={<Courses/>}/>

                    <Route
                        path="/sandbox"
                        element={
                            <PrivateRoute>
                                <Sandbox userInfo={userInfo} token={token}/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/sandbox/:sandboxId"
                        element={
                            <PrivateRoute>
                                <Sandbox userInfo={userInfo} token={token}/>
                            </PrivateRoute>
                        }
                    />

                    <Route path="/demo" element={<Demo/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="*" element={<MainContent/>}/>
                </Routes>
            </div>

            <Dialog open={loginOpen} onClose={handleCloseLogin}>
                <DialogContent>
                    <LoginForm onLoginSuccess={onLoginSuccess} onClose={handleCloseLogin} />
                </DialogContent>
            </Dialog>

            <Dialog open={registerOpen} onClose={handleCloseRegister}>
                <DialogContent>
                    <RegisterForm onRegisterSuccess={handleRegisterSuccess} onClose={handleCloseRegister} />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AppRoutes;