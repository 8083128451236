import React from 'react';

const Courses = () => {
    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Курсы</h1>
            <div className="bg-gray-100 p-4 rounded">
                <p className="text-center text-gray-600 font-semibold">Раздел в разработке</p>
            </div>
        </div>
    );
};

export default Courses;
