import React, { useState, useEffect, useRef } from 'react';
import DraggableHeader from './DraggableHeader';

const LayersPanel = ({ currentLayer, onLayerChange }) => {
    const [isMinimized, setIsMinimized] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const panelRef = useRef(null);

    const layers = [
        { id: 1, name: 'Верхнеуровневая архитектура' },
        { id: 2, name: 'Подробная архитектура' },
    ];

    useEffect(() => {
        const updatePosition = () => {
            const desiredTopOffset = 150; // расстояние от верхнего края в пикселях (учитывая высоту header)
            setPosition({
                top: desiredTopOffset,
                left: 16 // 1rem = 16px, соответствует классу left-4
            });
        };

        updatePosition();
        window.addEventListener('resize', updatePosition);

        return () => window.removeEventListener('resize', updatePosition);
    }, []);

    const handleLayerClick = (layer) => {
        onLayerChange(layer.name);
    };

    const handleMinimize = () => setIsMinimized(true);
    const handleRestore = () => setIsMinimized(false);

    const panelStyle = {
        position: 'fixed',
        top: `${position.top}px`,
        left: `${position.left}px`,
        width: '256px',
        maxHeight: `calc(100vh - ${position.top + 20}px)`, // 20px для нижнего отступа
        display: 'flex',
        flexDirection: 'column',
        zIndex: 50
    };

    return (
        <div ref={panelRef} style={panelStyle} className="bg-gray-100 shadow-lg rounded-lg">
            {!isMinimized ? (
                <>
                    <DraggableHeader
                        title="Слои"
                        onMinimize={handleMinimize}
                    />
                    <div className="flex-grow overflow-y-auto p-2">
                        {layers.map((layer) => (
                            <div
                                key={layer.id}
                                className={`flex items-center mb-2 p-2 rounded-lg cursor-pointer hover:bg-gray-200 ${
                                    currentLayer === layer.name ? 'bg-gray-300' : ''
                                }`}
                                onClick={() => handleLayerClick(layer)}
                            >
                                <span className="text-sm font-normal font-sans">
                                    {layer.name}
                                </span>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <div
                    className="p-2 bg-white rounded-full shadow-lg cursor-pointer"
                    onClick={handleRestore}
                >
                    <span className="text-black">📋 Панель слоёв</span>
                </div>
            )}
        </div>
    );
};

export default LayersPanel;