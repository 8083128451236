// src/services/apiService.js
import axios from 'axios';
import config from '../config';
import i18n from '../i18n';

// Конфигурация Axios с базовым URL
const api = axios.create({
  baseURL: config.apiUrl,
});

// Метод для авторизации пользователя
export const login = async (username, password) => {
  const response = await api.post('/users/login', {
    username,
    password,
  });
  return response.data;
};

// Метод для регистрации пользователя
export const register = async (username, email, password) => {
  const response = await api.post('/users/register', {
    username,
    email,
    password,
  });
  return response.data;
};

// Метод для подачи заявки на альфа тестера
export const add_tester = async (username, email, fullAdditionalInfo) => {
  const response = await api.post('/users/add-tester/', {
    name: username,
    email: email,
    add_information: fullAdditionalInfo,
  });
  return response.data;
};

// Метод для получения информации о пользователе
export const getUserInfo = async (token) => {
  const response = await api.get('/users/user-info', {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
  return response.data;
};

// Метод для старта процесса Camunda в песочнице
export const startCamundaProcess = async (token, prompt, userId, is_reassembly, sandbox) => {
  const currentLanguage = i18n.language; // Получаем текущий язык пользователя
  const sandbox_id = parseInt(sandbox, 10);
  const response = await api.post(
      '/sandbox/start-process',
      { prompt, user_id: userId, language: currentLanguage, is_reassembly: is_reassembly, sandbox: sandbox_id },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    return response.data;
  };

// Метод для получения списка песочниц пользователя
export const fetchSandboxes = async (token, userId) => {
  try {
    const response = await api.get(`/sandbox/sandbox/?owner=${userId}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching sandboxes:", error.message);
    return [];
  }
};

// Метод для получения активного инстанса песочницы
export const fetchInstances = async (token, sandboxId) => {
  try {
    const response = await api.get(`/sandbox/instance/?sandbox=${sandboxId}&archive=False`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching sandboxes:", error.message);
    return [];
  }
};

// Метод для получения статуса процесса генерации песочницы
export const getProcessStage = async (token, ProcessId) => {
  try {
    const response = await api.get(`/sandbox/process/?id=${ProcessId}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching process stage:", error.message);
    return null;
  }
};

// Метод для получения компонентов песочницы
export const fetchSandboxComponents = async (token, sandboxId) => {
  try {
    const response = await api.get(`/sandbox/component/?archive=False&sandbox=${sandboxId}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching sandbox components:", error.message);
    return [];
  }
};


// Метод для получения связей между компонентами песочницы
export const fetchSandboxComponentsRelations = async (token, sandboxId, componentIds) => {
  try {
    // Запрос связей для каждого компонента
    const promises = componentIds.map(id =>
        api.get(`/sandbox/relation/?sandbox=${sandboxId}&from_component=${id}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
    );

    const responses = await Promise.all(promises);

    // Собираем все связи в один массив
    const relations = responses.flatMap(response => response.data);

    return relations;
  } catch (error) {
    console.error("Error fetching sandbox relations:", error.message);
    return [];
  }
};


// Метод для обновления наименования песочницы
export const updateSandboxName = async (token, sandboxId, newName) => {
  try {
    const response = await api.patch(
        `/sandbox/sandbox/${sandboxId}/`,
        { name: newName },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating sandbox name:", error.message);
    throw error;
  }
};